.color-primary {
  color: #0081c8; }

.gallery {
  width: 75%;
  max-height: 100%;
  margin: 0 auto;
  flex-direction: column;
  border-radius: 9px;
  position: relative;
  transform: translateX(-2%); }
  @media (max-width: 1600px) {
    .gallery {
      padding-left: 50px;
      padding-right: 50px; } }
  .gallery__main {
    max-height: calc(100% - 140px);
    position: relative;
    overflow: hidden; }
    @media (max-width: 1440px) {
      .gallery__main {
        max-height: calc(100% - 110px); } }
    @media (max-width: 992px) {
      .gallery__main {
        max-height: calc(100% - 90px); } }
    @media (max-width: 768px) {
      .gallery__main {
        max-height: calc(100% - 70px); } }
    .gallery__main .slick-slide img {
      display: block !important; }
    .gallery__main .slick-slider {
      max-height: 100%;
      position: static !important; }
    .gallery__main .slick-slider, .gallery__main .slick-list, .gallery__main .slick-track {
      height: 100%;
      max-height: 100%; }
    .gallery__main .slick-list {
      border-radius: 9px; }
    .gallery__main .slick-slide {
      position: relative; }
      .gallery__main .slick-slide img {
        position: relative; }
  .gallery__nav {
    margin: -140px 0 0;
    padding-top: 30px;
    padding-bottom: 25px;
    padding-left: 180px;
    padding-right: 180px;
    height: 140px;
    overflow: hidden;
    background: rgba(12, 12, 12, 0.87);
    position: relative;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px; }
    @media (max-width: 1600px) {
      .gallery__nav {
        height: 110px;
        margin-top: -110px; } }
    @media (max-width: 992px) {
      .gallery__nav {
        max-height: 90px; } }
    @media (max-width: 768px) {
      .gallery__nav {
        max-height: 70px; } }
  .gallery__nav .slick-slide {
    padding: 0 20px;
    opacity: .54;
    transition: opacity .3s ease-in-out;
    cursor: pointer; }
    .gallery__nav .slick-slide.slick-current, .gallery__nav .slick-slide:hover {
      opacity: 1; }
  .gallery__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 14px;
    line-height: 1.2em;
    color: #043552;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 17px 35px;
    z-index: 2; }
  .gallery .slick-arrow {
    border: none;
    text-indent: 2000px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 16px;
    z-index: 1;
    cursor: pointer;
    background-color: transparent; }
    .gallery .slick-arrow:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 30px;
      margin: -15px 0 0 -8px;
      background-image: url(../../assets/img/gallery_arrow_left.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: transparent;
      transition: transform .4s ease-in-out; }
    .gallery .slick-arrow.slick-next {
      right: -130px; }
      .gallery .slick-arrow.slick-next:after {
        transform: rotate(180deg);
        margin-left: -10px; }
      .gallery .slick-arrow.slick-next:hover:after {
        transform: rotate(180deg) translateX(-15%); }
    .gallery .slick-arrow.slick-prev {
      left: -130px; }
      .gallery .slick-arrow.slick-prev:hover:after {
        transform: translateX(-15%); }
