.color-primary {
  color: #0081c8; }

.video-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 9px; }
  .video-container__inner {
    margin-left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
    flex: 0 0 100%; }
  .video-container video, .video-container img, .video-container canvas {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% top;
    overflow: hidden; }
