.color-primary {
  color: #0081c8; }

.overlay-modal-outer {
  background: inherit;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  opacity: 0; }
  .overlay-modal-outer._show-modal {
    opacity: 1; }
  .overlay-modal-outer:before {
    content: '';
    filter: blur(10px);
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background: inherit;
    z-index: 0; }
    ._isEdge .overlay-modal-outer:before {
      background-image: url(../../assets/img/main-bg-blur.jpg);
      background-size: 100%;
      background-repeat: no-repeat; }
  .overlay-modal-outer:after {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background-color: rgba(51, 51, 51, 0.2);
    z-index: 0; }
  .modal-transition-enter .overlay-modal-outer {
    opacity: 0; }
  .modal-transition-enter-active .overlay-modal-outer {
    opacity: 1; }
  .modal-transition-enter-done .overlay-modal-outer {
    opacity: 1; }
  .modal-transition-exit .overlay-modal-outer {
    opacity: 1; }
  .modal-transition-exit-active .overlay-modal-outer {
    opacity: 0; }

.overlay-modal {
  position: relative;
  max-width: 100%;
  transform-origin: top center;
  background-size: 100%;
  border-radius: 10px;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.3s;
  z-index: 11;
  opacity: 0; }
  ._show-modal .overlay-modal {
    opacity: 1; }
  .modal-transition-enter .overlay-modal {
    opacity: 0;
    transform: translate(0, -40px); }
  .modal-transition-enter-active .overlay-modal {
    opacity: 1;
    transform: translate(0, 0); }
  .modal-transition-enter-done .overlay-modal {
    opacity: 1;
    transform: none; }
  .modal-transition-exit .overlay-modal {
    opacity: 1;
    transform: translate(0, 0); }
  .modal-transition-exit-active .overlay-modal {
    opacity: 0;
    transform: translate(0, -40px); }
