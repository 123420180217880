.color-primary {
  color: #0081c8; }

.throbber {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: opacity 0.5s ease-in-out, z-index 0.5s linear 0.4s;
  background-color: transparent;
  z-index: 1;
  width: 205px;
  height: 205px;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -50%); }
  @media (max-width: 1600px) {
    .throbber {
      width: 150px;
      height: 150px; } }
  .throbber_video {
    top: 43%; }
  .throbber svg {
    opacity: .6;
    fill: #fff;
    width: 100%;
    height: 100%; }
  ._loaded .throbber {
    opacity: 0;
    z-index: -1; }
