.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.description-btn {
  text-transform: uppercase;
  font-size: 15px;
  position: absolute;
  top: 210px;
  left: 27px;
  z-index: 2;
  cursor: pointer;
  color: #F2F2F2; }
  @media (max-width: 1600px) {
    .description-btn {
      font-size: 11px;
      top: 157px;
      left: 18px; } }
  .description-btn_detail {
    left: 80px;
    top: 90px; }
  .description-btn:before {
    content: '';
    display: block;
    width: 140%;
    height: 380%;
    background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
  .description-btn:hover:before {
    opacity: .9;
    transform: translate(-50%, -50%) scale(1); }
  .description-btn__inner {
    position: relative;
    z-index: 1; }
  .description-btn svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 1600px) {
      .description-btn svg {
        width: 36px;
        height: 36px; } }
