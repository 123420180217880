.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0; }

.bg-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }
