.color-primary {
  color: #0081c8; }

.detail {
  position: absolute;
  background: inherit;
  overflow: hidden;
  border-radius: 10px;
  width: 253px;
  height: 180px;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  @media (max-width: 1600px) {
    .detail {
      width: 180px;
      height: 125.5px; } }
  .detail:before {
    content: '';
    filter: blur(10px);
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background: inherit;
    z-index: 1; }
  .detail:after {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background-color: rgba(51, 51, 51, 0.2);
    z-index: 1; }
  .detail_1 {
    top: 0; }
  .detail_2 {
    top: 190px;
    transition-delay: .1s; }
    @media (max-width: 1600px) {
      .detail_2 {
        top: 135.5px; } }
  .detail_3 {
    top: 380px;
    transition-delay: .2s; }
    @media (max-width: 1600px) {
      .detail_3 {
        top: 271px; } }
  .detail_4 {
    top: 570px;
    transition-delay: .3s; }
    @media (max-width: 1600px) {
      .detail_4 {
        top: 406.5px; } }
  .detail_left {
    left: -263px;
    transform-origin: right center; }
    @media (max-width: 1600px) {
      .detail_left {
        left: -190px; } }
  .detail_right {
    right: -263px;
    transform-origin: 0% 0%; }
    @media (max-width: 1600px) {
      .detail_right {
        right: -190px; } }
  .detail__inner {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 2;
    padding: 10px 0; }
  .detail__img {
    width: 138px;
    text-align: center;
    padding-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row;
    flex: 0 0; }
    .detail__img:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      transition: all .4s ease-in-out;
      z-index: 0; }
    .detail__img > img {
      max-width: 100%;
      max-height: 110px;
      position: relative;
      z-index: 1;
      margin: 0 auto; }
      @media (max-width: 1600px) {
        .detail__img > img {
          max-height: 60px; } }
  .detail__inner:hover .detail__img:before, .detail__inner.active .detail__img:before {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 1; }
  .detail__name {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4em;
    text-align: center;
    text-transform: uppercase;
    color: #F2F2F2;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    padding: 0 10px; }
    @media (max-width: 1600px) {
      .detail__name {
        font-size: 10px; } }
  .detail-transition-enter .detail {
    opacity: 0;
    transform: translate(30px, 0) scale(0.7); }
    .detail-transition-enter .detail_right {
      transform: translate(-30px, 0) scale(0.7); }
  .detail-transition-enter-active .detail {
    opacity: 1;
    transform: translate(0%, 0) scale(1); }
  .detail-transition-exit .detail {
    opacity: 1;
    transform: translate(0%, 0) scale(1); }
  .detail-transition-exit-active .detail {
    opacity: 0;
    transform: translate(30px, 0) scale(0.7); }
    .detail-transition-exit-active .detail_right {
      transform: translate(-30px, 0) scale(0.7); }

.details-outer {
  background: inherit; }
