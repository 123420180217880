.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.page {
  flex: 1 0 auto;
  min-height: 100%;
  height: 100%;
  position: relative;
  background-image: url(../../assets/img/bg2.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden; }
  @media (max-width: 480px) {
    .page {
      height: auto; } }
  .page.routefade-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.content {
  padding-left: 60px;
  padding-right: 60px; }

.logo {
  background-image: url(../../assets/img/logo2.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 216px;
  height: 83px;
  position: absolute;
  top: 140px;
  left: 60px;
  z-index: 1; }
  @media (max-width: 1600px) {
    .logo {
      left: 50px;
      width: 170px;
      top: 120px; } }

.menu-outer {
  background: inherit; }
  ._isEdge .menu-outer {
    background: none; }

.menu-btn {
  width: 56px;
  height: 38px;
  position: absolute;
  bottom: 120px;
  right: 177px;
  cursor: pointer;
  z-index: 5; }
  @media (max-width: 1600px) {
    .menu-btn {
      width: 40px;
      bottom: 50px;
      right: 145px; } }
  .menu-btn:before {
    content: '';
    display: block;
    width: 153px;
    height: 121px;
    background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: .9;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    animation: menu-btn-pulse 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite; }
    @media (max-width: 1600px) {
      .menu-btn:before {
        width: 270%;
        height: 260%; } }
  .menu-btn:hover:before {
    opacity: .9;
    transform: translate(-50%, -50%) scale(1);
    animation-play-state: paused; }
  .menu-btn svg {
    width: 100%;
    position: relative; }

@keyframes menu-btn-pulse {
  0% {
    transform: translate(-50%, -50%) scale(0); }
  50% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-50%, -50%) scale(0); } }

.right-buttons {
  position: absolute;
  bottom: 120px;
  left: 60px;
  z-index: 5; }
  @media (max-width: 1600px) {
    .right-buttons {
      bottom: 50px;
      left: 50px; } }
  .right-buttons > * {
    margin-top: 40px; }

.exit-btn {
  font-size: 17px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  display: block;
  position: relative;
  cursor: pointer; }
  .exit-btn:before {
    content: '';
    display: block;
    width: 190%;
    height: 190%;
    background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
  .exit-btn:hover:before {
    opacity: .9;
    transform: translate(-50%, -50%) scale(1);
    animation-play-state: paused; }
  .exit-btn > span {
    position: relative;
    z-index: 1; }
