.color-primary {
  color: #0081c8; }

.mobile-placeholder {
  position: fixed;
  z-index: 1000;
  background-color: #14708a;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: none;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1440px) {
    .mobile-placeholder {
      display: flex; } }
  .mobile-placeholder__inner {
    padding: 0 50px; }
    @media (max-width: 640px) {
      .mobile-placeholder__inner {
        padding: 0 20px; } }
  .mobile-placeholder__text {
    padding: 0 60px;
    font-size: 48px;
    line-height: 1.6em;
    text-align: center; }
    @media (max-width: 1440px) {
      .mobile-placeholder__text {
        font-size: 46px; } }
    @media (max-width: 1280px) {
      .mobile-placeholder__text {
        font-size: 42px; } }
    @media (max-width: 992px) {
      .mobile-placeholder__text {
        font-size: 36px;
        padding: 0 40px; } }
    @media (max-width: 768px) {
      .mobile-placeholder__text {
        font-size: 24px;
        padding: 0 20px; } }
    @media (max-width: 640px) {
      .mobile-placeholder__text {
        padding: 0;
        font-size: 22px; } }
  .mobile-placeholder__logo {
    background-image: url(../../assets/img/logo2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 540px;
    height: 220px;
    margin: 0 auto 70px; }
    @media (max-width: 1440px) {
      .mobile-placeholder__logo {
        width: 500px;
        height: 210px; } }
    @media (max-width: 1280px) {
      .mobile-placeholder__logo {
        width: 480px;
        height: 200px; } }
    @media (max-width: 992px) {
      .mobile-placeholder__logo {
        width: 420px;
        height: 160px;
        margin-bottom: 60px; } }
    @media (max-width: 768px) {
      .mobile-placeholder__logo {
        width: 300px;
        height: 120px;
        margin-bottom: 50px; } }
    @media (max-width: 640px) {
      .mobile-placeholder__logo {
        width: 270px;
        height: 110px; } }
    @media (max-width: 480px) {
      .mobile-placeholder__logo {
        width: 200px;
        height: 80px; } }
