.color-primary {
  color: #0081c8; }

.modal-outer {
  background: inherit;
  background-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.modal {
  position: relative;
  transform-origin: top center;
  background: inherit;
  background-size: 100%;
  border-radius: 10px;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .modal-transition-enter .modal {
    opacity: 0;
    transform: translate(0, -40px); }
  .modal-transition-enter-active .modal {
    opacity: 1;
    transform: translate(0, 0); }
  .modal-transition-exit .modal {
    opacity: 1;
    transform: translate(0, 0); }
  .modal-transition-exit-active .modal {
    opacity: 0;
    transform: translate(0, -40px); }
  .about-screen .modal {
    margin-left: -9%; }
  .mudline-screen .modal {
    margin-left: -7%; }
  .detail-screen .modal {
    margin-left: -7%; }
