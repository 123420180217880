.color-primary {
  color: #0081c8; }

.video-controls {
  position: relative;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  background: rgba(12, 12, 12, 0.87);
  display: flex;
  z-index: 4;
  transition: transform .5s ease-in-out;
  height: 100px;
  margin-top: -99px; }
  .video-controls__sound {
    padding: 60px;
    cursor: pointer; }
    @media (max-width: 1440px) {
      .video-controls__sound {
        padding: 45px; } }
    .video-controls__sound > svg {
      display: block;
      width: 33px;
      height: 27px;
      transform: rotate(180deg); }
      .video-controls__sound > svg rect {
        fill: #a7a9ac;
        transition: height .5s linear;
        animation: sound 1200ms linear infinite; }
      .video-controls__sound > svg #sound_3 {
        fill: #1f508b; }
      .video-controls__sound > svg #sound_2 {
        animation-delay: 300ms; }
      .video-controls__sound > svg #sound_1 {
        animation-delay: 600ms; }
    .video-controls__sound._muted > svg rect {
      height: 8px;
      animation: none; }
  .video-controls__scroll {
    padding: 60px 60px 60px 40px;
    display: none;
    align-items: center; }
    @media (max-width: 1440px) {
      .video-controls__scroll {
        padding: 45px; } }
  .video-controls__btn {
    display: block;
    width: 11px;
    height: 19px;
    background-image: url(../../assets/img/scroll_left.png);
    background-repeat: no-repeat;
    background-position: left top;
    position: relative;
    cursor: pointer; }
    .video-controls__btn:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 11px;
      height: 19px;
      background-image: url(../../assets/img/scroll_left.png);
      background-repeat: no-repeat;
      background-position: -12px top;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      will-change: opacity; }
    .video-controls__btn._active:hover:after {
      opacity: 1; }
    .video-controls__btn_right {
      transform: rotate(180deg); }
  .video-controls__mid {
    width: 22px;
    height: 19px;
    margin: 0 6px;
    cursor: pointer; }
    .video-controls__mid:hover:after {
      border-color: #0081c8; }
    .video-controls__mid:after {
      content: '';
      border-top: 2px solid #a7a9ac;
      width: 100%;
      display: block;
      margin-top: 9px; }

._dark-bg .video-controls__sound-alert {
  color: #fff; }

@keyframes sound {
  0% {
    height: 27px; }
  50% {
    height: 8px; }
  100% {
    height: 27px; } }
