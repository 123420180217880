.color-primary {
  color: #0081c8; }

.about {
  padding: 50px 205px 60px; }
  @media (max-width: 1600px) {
    .about {
      padding: 50px 170px 60px; } }
  .about__item {
    display: block;
    margin-bottom: 38px; }
    .about__item:last-child {
      margin-bottom: 0; }
  .about__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4em;
    text-align: center;
    text-transform: uppercase;
    color: #F2F2F2;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 8px; }
  .about__img {
    position: relative;
    border-radius: 9px; }
    .about__img > img {
      display: block;
      width: 465px;
      height: 270px;
      border-radius: 9px; }
      @media (max-width: 1600px) {
        .about__img > img {
          width: 325px;
          height: 190px; } }
    .about__img:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 4px 10px 10px rgba(111, 207, 151, 0.5);
      border-radius: 9px;
      opacity: 0;
      transition: opacity 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .about__item:hover .about__img:after {
    opacity: 1; }
