.color-primary {
  color: #0081c8; }

.video {
  margin: 0 auto;
  position: relative; }

.vimeo-player {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%; }

.vimeo-player-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }
