.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.close-btn {
  position: absolute;
  top: 0;
  right: -108px;
  width: 98px;
  height: 100px;
  background: inherit;
  border-radius: 11px;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  @media (max-width: 1600px) {
    .close-btn {
      width: 60px;
      height: 62px;
      right: -70px; } }
  .close-btn_out {
    right: -150px; }
    @media (max-width: 1600px) {
      .close-btn_out {
        right: -100px; } }
  .close-btn_no-bg {
    background: none; }
  .close-btn_no-bg .close-btn__inner {
    padding-top: 0; }
  .close-btn_no-bg .close-btn__inner:before {
    top: 40%; }
  .close-btn__inner {
    padding: 20px;
    display: block;
    cursor: pointer; }
    @media (max-width: 1600px) {
      .close-btn__inner {
        padding: 12px; } }
    .close-btn__inner:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
    .close-btn__inner:hover:before {
      opacity: .9;
      transform: translate(-50%, -50%) scale(1); }
  .close-btn svg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; }
  ._show-details .close-btn {
    transform: translate(263px, 0); }
    @media (max-width: 1600px) {
      ._show-details .close-btn {
        transform: translate(190px, 0); } }
