.color-primary {
  color: #0081c8; }

.video__progressbar {
  position: relative;
  bottom: 67px;
  z-index: 5;
  display: flex;
  align-items: center;
  padding-left: 115px;
  padding-right: 115px; }
  @media (max-width: 1600px) {
    .video__progressbar {
      padding: 0 50px; } }

.video-progress {
  height: 7px;
  border-radius: 7px;
  z-index: 4;
  position: relative;
  flex: 1 1 90%; }
  @media (max-width: 768px) {
    .video-progress {
      height: 12px; } }
  .video-progress .rangeslider {
    margin: 0;
    background-color: transparent;
    box-shadow: none; }
  .video-progress .rangeslider-horizontal {
    height: 6.71px;
    background: #C4C4C4;
    border-radius: 2.5px;
    position: relative; }
    @media (max-width: 768px) {
      .video-progress .rangeslider-horizontal {
        height: 12px; } }
    .video-progress .rangeslider-horizontal:before {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: 0;
      right: 0; }
  .video-progress .rangeslider-horizontal .rangeslider__fill {
    border-radius: 2.5px;
    background-color: #fff;
    box-shadow: none;
    transition: width .3s linear;
    will-change: width; }
  .video-progress .rangeslider-horizontal .rangeslider__handle {
    width: 0;
    height: 0;
    border: 0;
    overflow: visible;
    transition: left .3s linear; }
    .video-progress .rangeslider-horizontal .rangeslider__handle:after {
      width: 13.5px !important;
      height: 13.5px !important;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      background-color: #fff;
      box-shadow: none; }
  .video-progress .rangeslider__handle-label {
    display: none; }
  .video-progress .rangeslider__labels {
    display: none !important; }
  .video-progress__counter {
    position: absolute;
    right: -90px;
    color: #F2F2F2;
    top: -3px;
    font-size: 12px;
    white-space: nowrap; }
    @media (max-width: 768px) {
      .video-progress__counter {
        font-size: 10px; } }
    .video-progress__counter > i {
      font-style: normal;
      color: #F2F2F2; }

.pause-btn {
  margin-right: 60px;
  height: 35px;
  width: 26px;
  min-width: 26px;
  position: relative;
  cursor: pointer; }
  .pause-btn svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .pause-btn__play {
    display: none; }
  .pause-btn_paused .pause-btn__play {
    display: block; }
  .pause-btn_paused .pause-btn__pause {
    display: none; }

._dark-bg .video-progress {
  background-color: rgba(255, 255, 255, 0.8); }

._dark-bg .video-progress__counter {
  color: #fff; }
