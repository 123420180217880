.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.menu {
  position: absolute;
  width: 292px;
  bottom: 198px;
  right: 60px;
  overflow: hidden;
  border-radius: 10px;
  padding: 40px 0;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background: inherit;
  transform-origin: bottom center;
  z-index: 2; }
  @media (max-width: 1600px) {
    .menu {
      bottom: 120px;
      right: 50px;
      width: 230px; } }
  .menu:before {
    content: '';
    filter: blur(10px);
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background: inherit;
    z-index: 0; }
  .menu:after {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background-color: rgba(51, 51, 51, 0.2);
    z-index: 0; }
  .menu__item {
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    text-align: center; }
    @media (max-width: 1600px) {
      .menu__item {
        margin-bottom: 30px; } }
    .menu__item:last-child {
      margin-bottom: 0; }
  .menu__text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3em;
    text-align: center;
    text-transform: uppercase;
    color: #F2F2F2;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    @media (max-width: 1600px) {
      .menu__text {
        font-size: 15px; } }
  .menu__img {
    margin: 0 auto 20px;
    text-align: center;
    position: relative;
    display: inline-block;
    min-width: 145px;
    transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    @media (max-width: 1600px) {
      .menu__img {
        min-width: 90px;
        margin-bottom: 17px; } }
    .menu__img:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
    .menu__img img {
      position: relative; }
    .menu__img_1 img {
      height: 122px; }
      @media (max-width: 1600px) {
        .menu__img_1 img {
          height: 100px; } }
    .menu__img_2 img {
      height: 127px; }
      @media (max-width: 1600px) {
        .menu__img_2 img {
          height: 104px; } }
    .menu__img_3 img {
      width: 166px; }
      @media (max-width: 1600px) {
        .menu__img_3 img {
          width: 120px; } }
  .menu__item-inner:hover .menu__img:before, .menu__item-inner.active .menu__img:before {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 1; }
  .menu__item-inner:hover .menu__img_3:before, .menu__item-inner.active .menu__img_3:before {
    transform: translate(-50%, -50%) scale(2.3);
    opacity: 1; }
  .menu.menu-transition-enter {
    opacity: 0;
    transform: translateY(40px); }
  .menu.menu-transition-enter-active {
    opacity: 1;
    transform: translateY(0); }
  .menu.menu-transition-exit {
    opacity: 1;
    transform: translateY(0); }
  .menu.menu-transition-exit-active {
    opacity: 0;
    transform: translateY(40px); }
  .menu.menu-transition-enter .menu__text {
    opacity: 0; }
  .menu.menu-transition-enter-active .menu__text {
    opacity: 1; }
  .menu.menu-transition-exit .menu__text {
    opacity: 1; }
  .menu.menu-transition-exit-active .menu__text {
    opacity: 0; }
  .menu.menu-transition-enter .menu__img {
    transform: scale(0.9); }
  .menu.menu-transition-enter-active .menu__img {
    transform: scale(1); }
  .menu.menu-transition-exit .menu__img {
    transform: scale(1); }
  .menu.menu-transition-exit-active .menu__img {
    transform: scale(0.9); }
