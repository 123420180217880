.backdrop {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background: inherit; }
  ._isEdge .backdrop {
    background: none transparent; }
  .backdrop:before {
    content: '';
    filter: blur(10px);
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background: inherit;
    z-index: 1; }
  .backdrop:after {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background-color: rgba(51, 51, 51, 0.2);
    z-index: 1; }
  .backdrop__inner {
    position: relative;
    z-index: 2; }
