.color-primary {
  color: #0081c8; }

.screen-title {
  position: fixed;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 900;
  font-size: 20px;
  line-height: 1.4em;
  letter-spacing: 0.03em;
  color: #F2F2F2;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  max-width: 300px;
  transition: all .3s ease;
  z-index: 20; }
  @media (max-width: 1600px) {
    .screen-title {
      left: 50px; } }
  .screen-title_rotate {
    transform: translateX(-50%) rotate(-90deg);
    transform-origin: 50% 0%; }
  .screen-title i {
    font-style: normal;
    color: #219653;
    font-weight: 500;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4); }
  .modal-transition-enter .screen-title {
    opacity: 0; }
  .modal-transition-enter-active .screen-title {
    opacity: 1; }
  .modal-transition-exit .screen-title {
    opacity: 1; }
  .modal-transition-exit-active .screen-title {
    opacity: 0; }
