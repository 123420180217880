.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: inherit; }
  .preloader > div {
    height: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .preloader__logo {
    height: 122px;
    width: 319px;
    background-image: url(../../assets/img/logo2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: 60px; }
  .preloader__progress {
    display: inline-block;
    width: 269px;
    height: 7px;
    border-radius: 2.5px;
    background-color: #C4C4C4;
    overflow: hidden; }
    .preloader__progress > span {
      display: block;
      height: 100%;
      background-color: #fff;
      border-radius: 2.5px;
      width: 26%; }
  .preloader__num {
    color: #F2F2F2;
    font-size: 20px;
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle; }
