@charset "UTF-8";
@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto-Regular.woff") format("woff"), url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto-Light.woff") format("woff"), url("./assets/fonts/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto-Bold.woff") format("woff"), url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700; }

/* Reset */
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

:focus {
  outline: none; }

html {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

body {
  padding: 0;
  margin: 0; }

img {
  border: 0;
  display: inline-block; }

ul {
  list-style: none; }

p {
  margin: 1rem 0;
  line-height: 1.5; }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  will-change: color, background;
  transition: color .15s; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.color-primary {
  color: #0081c8; }

._nowrap {
  white-space: nowrap; }

._underline {
  text-decoration: underline; }

._bg-inherit {
  background: inherit; }
  ._isEdge ._bg-inherit {
    background: none transparent; }

html, body {
  height: 100%; }

body {
  font-family: 'Roboto', 'sans-serif';
  color: #58595b;
  background-color: #14708a;
  width: 100%;
  overflow: hidden; }
  body:after {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    transition: background-color .2s, transform .8s ease;
    background-color: rgba(0, 0, 0, 0);
    z-index: -10; }
  body.mobile-menu-expanded:after {
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    transform: translateX(-300px);
    z-index: 10; }
  body._frozen {
    overflow: hidden; }

#root {
  height: 100%;
  min-height: 100%; }
  #root > div {
    display: flex;
    height: 100%;
    min-height: 100%;
    flex-direction: column; }

ul {
  margin: 1em 0;
  padding: 0; }
  ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: .5em; }
    ul li:before {
      content: '';
      position: absolute;
      top: .4em;
      left: 0;
      width: 10px;
      height: 10px;
      background-color: #219653;
      border-radius: 10px;
      box-shadow: 0px 0px 5px 1px #219653; }

h1 {
  font-size: 28px;
  text-transform: uppercase;
  margin: 0 0 1em 0;
  line-height: 1.4em; }

h2 {
  font-size: 18px;
  text-transform: uppercase;
  margin: 1em 0 .5em; }

.subt {
  text-transform: uppercase;
  margin-top: -1.1em; }
  .subt:after {
    content: '';
    display: block;
    height: 10px;
    width: 70px;
    background-color: #219653;
    margin: 1.1em 0; }
