.color-primary {
  color: #0081c8; }

.description {
  max-width: 1060px;
  color: #fafafa;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  line-height: 1.4em;
  font-size: 18px;
  background-color: rgba(51, 51, 51, 0.3);
  padding: 60px 80px;
  border-radius: 9px; }
  .description ul:last-child {
    margin-bottom: 0; }
