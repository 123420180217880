.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.details-btn {
  position: absolute;
  top: 82px;
  left: 45px;
  width: 44px;
  height: 50px;
  cursor: pointer;
  z-index: 2; }
  @media (max-width: 1600px) {
    .details-btn {
      width: 30px;
      height: 32px;
      top: 62px;
      left: 32px; } }
  .details-btn:before {
    content: '';
    display: block;
    width: 180%;
    height: 180%;
    background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
  .details-btn:hover:before {
    opacity: .9;
    transform: translate(-50%, -50%) scale(1); }
  .details-btn svg {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    z-index: 1; }
  .details-btn__active svg {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
  ._show-details .details-btn svg {
    opacity: 0; }
  ._show-details .details-btn__active svg {
    opacity: 1; }
  .details-btn__text {
    color: #F2F2F2;
    font-size: 15px;
    text-transform: uppercase;
    position: absolute;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 1600px) {
      .details-btn__text {
        font-size: 11px;
        bottom: -17px; } }
