.color-primary {
  color: #0081c8; }

.item {
  padding: 80px 80px 60px;
  max-width: 392px; }
  @media (max-width: 1600px) {
    .item {
      padding: 60px 40px 50px;
      max-width: 270px; } }
  .item_wide {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 760px;
    width: 840px;
    max-width: 840px;
    padding: 40px 190px; }
    @media (max-width: 1600px) {
      .item_wide {
        min-height: 510px;
        height: 600px;
        width: 770px;
        max-width: 770px;
        padding: 80px 100px; } }
  .item__img {
    margin-bottom: 33px;
    text-align: center; }
    .item__img img {
      max-height: 525px; }
      @media (max-width: 1600px) {
        .item__img img {
          max-height: 340px; } }
  .item_wide .item__img img {
    max-width: 100%; }
  .item__video {
    margin-bottom: 33px;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 525px; }
    @media (max-width: 1600px) {
      .item__video {
        height: 340px; } }
    .item__video video {
      max-height: 525px; }
      @media (max-width: 1600px) {
        .item__video video {
          max-height: 340px; } }
  .item_wide .item__video img {
    max-width: 100%; }
  .item__name {
    font-size: 18px;
    line-height: 1.4em;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #F2F2F2;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5); }
    @media (max-width: 1600px) {
      .item__name {
        font-size: 16px; } }
  .item_wide .item__name {
    margin-bottom: 30px;
    font-size: 24px; }
  .item__description {
    font-size: 15px;
    line-height: 1.4em;
    color: #F2F2F2;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5); }
