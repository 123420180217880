.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.btn360 {
  text-transform: uppercase;
  font-size: 15px;
  position: absolute;
  top: 283px;
  left: 45px;
  width: 45px;
  height: 45px;
  z-index: 1;
  cursor: pointer;
  color: #F2F2F2; }
  @media (max-width: 1600px) {
    .btn360 {
      width: 30px;
      height: 30px;
      top: 217px;
      left: 32px; } }
  .btn360_wide {
    left: 98px;
    top: 160px; }
    @media (max-width: 1600px) {
      .btn360_wide {
        left: 94px;
        top: 155px; } }
  .btn360:before {
    content: '';
    display: block;
    width: 180%;
    height: 180%;
    background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
  .btn360:hover:before {
    opacity: .9;
    transform: translate(-50%, -50%) scale(1); }
  .btn360 svg {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    z-index: 1; }
