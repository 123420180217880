.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.circle-btn {
  position: absolute;
  left: 56.5%;
  bottom: 60px;
  height: 166px;
  width: 166px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  @media (max-width: 1600px) {
    .circle-btn {
      left: 55.5%;
      bottom: 25px; } }
  .circle-btn:before {
    content: '';
    display: block;
    width: 130%;
    height: 130%;
    background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.2);
    opacity: .9;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    animation: circle-btn-pulse 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite; }
  .circle-btn:hover:before {
    opacity: .9;
    animation-play-state: paused; }
  .circle-btn img {
    position: relative;
    height: 110px;
    z-index: 1; }
  .circle-btn svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .circle-btn svg .icon-circle-btn__dash {
      animation: rotate 40s linear infinite;
      transform-origin: center; }
  .circle-btn__text {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(0, -50%);
    font-size: 20px;
    line-height: 1.3em;
    letter-spacing: 0.03em;
    color: #F2F2F2;
    text-transform: uppercase;
    font-weight: 900;
    max-width: 300px;
    padding-right: 35px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5); }
    .circle-btn__text i {
      font-style: normal;
      color: #219653;
      font-weight: 500; }
  .modal-transition-enter .circle-btn {
    opacity: 0;
    transform: scale(0.4); }
  .modal-transition-enter-active .circle-btn {
    opacity: 1;
    transform: scale(1); }
  .modal-transition-exit .circle-btn {
    opacity: 1;
    transform: scale(1); }
  .modal-transition-exit-active .circle-btn {
    opacity: 0;
    transform: scale(0.4); }

@keyframes circle-btn-pulse {
  0% {
    transform: translate(-50%, -50%) scale(0); }
  50% {
    transform: translate(-50%, -50%) scale(1.1); }
  100% {
    transform: translate(-50%, -50%) scale(0); } }

@keyframes circle-btn-pulse1 {
  0% {
    transform: translate(-50%, -50%) scale(0); }
  100% {
    transform: translate(-50%, -50%) scale(1.1); } }
