.color-primary {
  color: #0081c8; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.routefade-enter {
  opacity: 0.01; }

.routefade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.routefade-exit {
  opacity: 1; }

.routefade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in; }

.no-fade .routefade-enter {
  opacity: 1; }

.no-fade .routefade-exit {
  opacity: 0; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.back-sound {
  cursor: pointer;
  margin-left: 5px; }
  .back-sound__btn {
    position: relative;
    width: 44px;
    height: 35px; }
    .back-sound__btn:before {
      content: '';
      display: block;
      width: 230%;
      height: 240%;
      background: radial-gradient(41.74% 41.74% at 50% 50%, rgba(111, 207, 151, 0.69) 0%, rgba(111, 207, 151, 0) 100%);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
      @media (max-width: 1600px) {
        .back-sound__btn:before {
          width: 230%;
          height: 240%; } }
    .back-sound__btn:hover:before {
      opacity: .9;
      transform: translate(-50%, -50%) scale(1); }
    .back-sound__btn svg {
      position: absolute;
      width: 100%; }
  .back-sound__on svg {
    transition: opacity .3s ease-in-out; }
  .back-sound__off svg {
    opacity: 0;
    transition: opacity .3s ease-in-out; }
  .back-sound_muted .back-sound__on svg {
    opacity: 0; }
  .back-sound_muted .back-sound__off svg {
    opacity: 1; }
